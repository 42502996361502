import { Dispatch } from 'redux';
import { Product } from '../../../apiTypes';
import { openTextNotification } from '../../../notification';
import changeCartItemAmount from './changeCartItemAmount';
import removeCartItem from './removeCartItem';

const updateAmount = (
  newAmount: number, place: string, product: Product,
) => (dispatch: Dispatch) => {
  if (newAmount === 0) {
    dispatch(openTextNotification('product_removed'));
    dispatch(removeCartItem(product, place));
    return;
  }

  dispatch(changeCartItemAmount(product, newAmount, place));
  dispatch(openTextNotification('product_amount_changed'));
};

export default updateAmount;
