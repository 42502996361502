import { useRouter } from 'next/router';
import { MouseEventHandler } from 'react';
import { useDispatch } from 'react-redux';
import ProductTile from 'src/components/views/ProductTile/ProductTile';
import useAddProductTo from 'src/hooks/useAddProductTo/useAddProductTo';
import updateAmount from 'src/redux/cart/general/operations/updateAmount';
import selectDeliveryType from 'src/redux/cart/general/selectDeliveryType';
import selectSelectedDelivery from 'src/redux/cart/general/selectSelectedDelivery';
import { useAppSelector } from 'src/redux/hooks';
import { Product } from '../../../redux/apiTypes';
import { selectCartItems } from '../../../redux/cart/general/cartSelectors';
import { placeProductToCart } from '../../../redux/cart/general/operations/placeProductToCart';
import { openModal } from '../../../redux/modals';
import openProductModalOrPage from '../../../redux/modals/openProductModalOrPage';
import { getInitialAmount } from '../../../utils/cart/getInitialAmount';
import useCartProductTileFavoriteProps from './useCartProductTileFavoriteProps';

export interface CartProductTileProps {
  data: Product;
  isInSlider?: boolean;
  productPlace: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  onReplace?: MouseEventHandler<HTMLButtonElement>;
  isReplacement?: boolean;
}

const CartProductTile = (props: CartProductTileProps) => {
  const {
    data,
    isInSlider,
    productPlace,
    onClick = () => {},
    isReplacement,
    onReplace,
  } = props;

  const { ean, unit, quantity } = data;

  const language = useAppSelector(s => s.app.language);
  const cartItems = useAppSelector(selectCartItems);
  const cartProduct = cartItems.find((p) => p.ean === ean);
  const cartType = useAppSelector(s => s.app.cartType);
  const isUserAddressSet = !!useAppSelector(selectSelectedDelivery);
  const deliveryType = useAppSelector(selectDeliveryType);
  const dispatch = useDispatch();
  const router = useRouter();
  const addProductTo = useAddProductTo();

  const {
    isFavorite,
    handleFavoriteClick,
  } = useCartProductTileFavoriteProps(productPlace, data);

  const handleAddProductToCart = () => {
    const productAmount = getInitialAmount(unit, quantity, ean);
    const product = { ...data, amount: productAmount };

    if (isUserAddressSet) {
      addProductTo(() => {
        dispatch(placeProductToCart(product, productPlace));
      }, data);
    } else {
      dispatch(openModal('addressModal', null, { onSetNewDelivery: () => dispatch(placeProductToCart(product, productPlace)) }));
    }
  };

  const handleLinkClick: MouseEventHandler<HTMLAnchorElement> = event => {
    event.preventDefault();
    event.stopPropagation();

    onClick(event);

    if (isReplacement || data.is_uber_item) {
      return;
    }

    dispatch(
      openProductModalOrPage(
        {
          ean: data.ean,
          slug: data.slug,
          backUrl: router.asPath,
          ctrlKeyIsPressed: event.ctrlKey,
          metaKeyIsPressed: event.metaKey,
        },
      ),
    );
  };

  const handleAmountChange = (newAmount: number) => {
    dispatch(updateAmount(newAmount, productPlace, data));
  };

  return (
    <ProductTile
      locale={language}
      product={data}
      isFavorite={isFavorite}
      isInSlider={isInSlider}
      isReplacement={isReplacement}
      productPlace={productPlace}
      itemIsAdded={!!cartProduct}
      amount={cartProduct?.amount}
      canBeAdded={!!cartType}
      onFavoriteClick={handleFavoriteClick}
      onReplace={onReplace}
      onAddToCart={handleAddProductToCart}
      onClick={handleLinkClick}
      onChangeAmount={handleAmountChange}
      deliveryType={deliveryType}
    />
  );
};

export default CartProductTile;
