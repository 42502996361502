import useToggles from "src/services/toggles/useToggles";
import { useLocalStorage } from "usehooks-ts";
import { getFeatureNotificationKey } from "../helpers/getFeatureNotificationKey";

export const useFeatureNotificationClosing = () => {
  const { FEATURE_NOTIFICATION_AVAILABLE, POSTOMAT_IS_AVAILABLE } = useToggles();
  const featureKey = getFeatureNotificationKey(POSTOMAT_IS_AVAILABLE);
  const [wasClosed, setWasClosed] = useLocalStorage( `${featureKey}_closed`, false);
  const isOpen = !wasClosed && FEATURE_NOTIFICATION_AVAILABLE;

  const closeNotification = () => {
    if(isOpen) {
      setWasClosed(true);
    }
  };

  return {
    isOpen,
    closeNotification,
  };
};
